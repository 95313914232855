import React from "react";
import { Link } from "gatsby";
import { slide as BurgerMenu } from "react-burger-menu";

const Menu = () => (
  <BurgerMenu disableAutoFocus>
    <Link to="/" className="menu-item">
      Startseite
    </Link>
    <br />
    <Link to="/ueberMich" className="menu-item">
      Über Mich
    </Link>
    <Link to="/familie" className="menu-item">
      Familie
    </Link>
    <Link to="/hochzeiten" className="menu-item">
      Hochzeiten
    </Link>
    <Link to="/babybauch" className="menu-item">
      Babybauch
    </Link>
    <Link to="/hunde" className="menu-item">
      Hunde
    </Link>
    <br />
    <Link to="/faq" className="menu-item">
      FAQ
    </Link>

    <a
      href="https://www.instagram.com/lichtbild_enthusiastin/"
      target="_blank"
      rel="noopener noreferrer"
    >
      <i className="fab fa-instagram" id="insta-logo"></i>
    </a>
  </BurgerMenu>
);

export default Menu;
